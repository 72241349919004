import { Buffer } from 'buffer';
import { useState, useEffect, useCallback } from 'react';
import {
  generateUserPreferencesPrompt,
  getIsAudioSilent,
} from '../utils/utils';
import { useUserContext } from '../contexts/UserContext';
import { useTeachersContext } from '../contexts/TeachersContext';
import chatAPI from '../api/chat/chatAPI';
import { useConfigContext } from '../contexts/ConfigContext';
import { useWidgetContext } from '../contexts/WidgetContext';
import useIsPWA from './useIsPWA';
import toast from 'react-hot-toast';

const useChat = () => {
  const { user, settingsMap } = useUserContext();
  const { teachersMap } = useTeachersContext();
  const { config } = useConfigContext();
  const { isTopicSuggestionsOpen, closeTopicSuggestions } = useWidgetContext();
  const isPWA = useIsPWA();
  const [messages, setMessages] = useState<any[]>([]);
  const [summaries, setSummaries] = useState<string[]>([]);
  const [areInputButtonsDisabled, setAreInputButtonsDisabled] = useState(false);
  const [latestAudioMessageId, setLatestAudioMessageId] = useState('');
  const [isDeletingChat, setIsDeletingChat] = useState(false);
  const [playingStateMap, setPlayingStateMap] = useState<
    Record<string, boolean>
  >({});
  const [audioMap, setAudioMap] = useState<Record<string, HTMLAudioElement>>(
    {},
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const stopAllAudios = useCallback(() => {
    Object.values(audioMap).forEach(audio => {
      audio.pause();
      audio.currentTime = 0;
    });
  }, [audioMap]);

  const selectedLanguageSettings = settingsMap[user.selectedLanguage]; //note consider setting this stuff in context
  const selectedTeacherId = selectedLanguageSettings.selectedTeacher;
  const teacher = teachersMap[selectedTeacherId];

  /**
   * Fetch chat on page load
   */
  useEffect(() => {
    fetchChatAndUpdateMessages(
      user._id,
      selectedTeacherId,
      user.userName,
      user.selectedLanguage,
    );
  }, [selectedTeacherId, user._id, user.selectedLanguage, user.userName]);

  const fetchChatAndUpdateMessages = async (
    userId: string,
    teacherId: string,
    userName: string,
    selectedLanguage: string,
  ) => {
    const { messages, summaries } = await chatAPI.fetchChat(
      userId,
      teacherId,
      userName,
      selectedLanguage,
    );

    setMessages(messages);
    setSummaries(summaries || []);
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleteModalOpen(false);
    setIsDeletingChat(true);

    setPlayingStateMap({});
    stopAllAudios();

    try {
      await chatAPI.deleteChat(user._id, selectedTeacherId);

      if (isPWA) {
        await fetchChatAndUpdateMessages(
          user._id,
          selectedTeacherId,
          user.userName,
          user.selectedLanguage,
        );
      } else {
        window.location.reload();
      }
    } catch (error) {
      toast.error(
        `Sorry, there was an error restarting the chat with ${teacher.name}. Please try again.`,
      );
    }

    setIsDeletingChat(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleTextSubmit = async (textValue: string) => {
    const userInput = textValue.trim();

    if (!userInput) return;

    if (isTopicSuggestionsOpen) closeTopicSuggestions();

    //note sanitize on backend, or do i want to show time stamps?
    const sanitizedMessages = messages.map(({ role, content }) => ({
      role,
      content,
    }));

    const newUserMessage = {
      role: 'user',
      content: userInput,
      // transliteration: null,
      // _id: '',
    };

    const typingAnimationMessage = {
      role: 'typingAnimation',
      content: '',
      // transliteration: null,
      // _id: '',
    };

    setMessages(prevMessages => [
      ...prevMessages,
      newUserMessage,
      typingAnimationMessage,
    ]);

    //note maybe abstract to hook
    const userPreferences = generateUserPreferencesPrompt(
      user.userName,
      selectedLanguageSettings.level,
      selectedLanguageSettings.language,
      selectedLanguageSettings.correctionFrequency,
      user.nativeLanguage,
      selectedLanguageSettings.nativeLanguageFrequency,
    );

    setAreInputButtonsDisabled(true);

    try {
      const { messages, aiAudioResponseBuffer } = await chatAPI.patchChat({
        userId: user._id,
        teacherId: selectedTeacherId,
        messages: [...sanitizedMessages, newUserMessage],
        teacherName: teacher.name,
        userPreferences,
        userPreferredSpeed: 1,
        isFemale: teacher.isFemale,
        isAutoPlayOn: user.isAutoPlayOn,
        language: user.selectedLanguage,
        languageCode: teacher.languageCode,
        voice: teacher.voice,
        azureVoice: teacher.azureVoice,
        ttsProvider: teacher.ttsProvider,
        summaries: config.featureFlags.features.isSystemPromptUsingSummaries
          ? summaries
          : undefined,
        textToTextModel: selectedLanguageSettings.textToTextModel,
      });

      if (aiAudioResponseBuffer) {
        const { _id } = messages[messages.length - 1];
        const audioUrl = URL.createObjectURL(
          new Blob([new Uint8Array(aiAudioResponseBuffer.data)], {
            type: 'audio/mpeg',
          }),
        );

        setAudioMap(prev => ({
          ...prev,
          [_id]: new Audio(audioUrl),
        }));
        setLatestAudioMessageId(_id);
      }

      setMessages(messages);
    } catch (error) {
      toast.error('Sorry, there was an error. Please try again.');
      //remove typing animation message
      setMessages(prevMessages => prevMessages.slice(0, -2));
    }

    setAreInputButtonsDisabled(false);
  };

  const handleMP3Submit = async (mp3Blob: Blob) => {
    if (isTopicSuggestionsOpen) closeTopicSuggestions();

    const reader = new FileReader();

    reader.onload = async () => {
      // Ensure that reader.result is not null and is an ArrayBuffer
      if (reader.result && reader.result instanceof ArrayBuffer) {
        const audioContext = new AudioContext();
        const audioBuffer = await audioContext.decodeAudioData(
          reader.result.slice(0),
        );

        if (getIsAudioSilent(audioBuffer)) {
          toast.error("Sorry, I couldn't hear you. Please try again.");
          return;
        }

        //note should i just send buffer?
        const buffer = Buffer.from(reader.result);
        const mp3Base64Str = buffer.toString('base64');

        //note sanitize on backend, or do i want to show time stamps?
        const sanitizedMessages = messages.map(({ role, content }) => ({
          role,
          content,
        }));

        const typingAnimationMessage = {
          role: 'typingAnimation',
          content: '',
          // transliteration: null,
          // _id: '',
        };

        setMessages(prevMessages => [...prevMessages, typingAnimationMessage]);

        try {
          const userPreferences = generateUserPreferencesPrompt(
            user.userName,
            selectedLanguageSettings.level,
            selectedLanguageSettings.language,
            selectedLanguageSettings.correctionFrequency,
            user.nativeLanguage,
            selectedLanguageSettings.nativeLanguageFrequency,
          );

          setAreInputButtonsDisabled(true);

          const { messages, aiAudioResponseBuffer } = await chatAPI.patchChat({
            userId: user._id,
            teacherId: selectedTeacherId,
            messages: sanitizedMessages,
            teacherName: teacher.name,
            userPreferences,
            isFemale: teacher.isFemale,
            isAutoPlayOn: user.isAutoPlayOn,
            mp3Base64Str,
            userPreferredSpeed: 1,
            language: user.selectedLanguage,
            languageCode: teacher.languageCode,
            voice: teacher.voice,
            azureVoice: teacher.azureVoice,
            ttsProvider: teacher.ttsProvider,
            summaries: config.featureFlags.features.isSystemPromptUsingSummaries
              ? summaries
              : undefined,
            textToTextModel: selectedLanguageSettings.textToTextModel,
          });

          if (aiAudioResponseBuffer) {
            const { _id } = messages[messages.length - 1];

            const audioUrl = URL.createObjectURL(
              new Blob([new Uint8Array(aiAudioResponseBuffer.data)], {
                type: 'audio/mpeg',
              }),
            );

            setAudioMap(prev => ({
              ...prev,
              [_id]: new Audio(audioUrl),
            }));

            setLatestAudioMessageId(_id);
          }

          setMessages(messages);
        } catch (error) {
          console.log(error);
          toast.error('Sorry, there was an error. Please try again.');
          //remove typing animation message
          setMessages(prevMessages => prevMessages.slice(0, -1));
        }
      } else {
        // Handle the error case where reader.result is null
        console.log('File read error or result is null');
        toast.error('Sorry, there was an error. Please try again.');
        //remove typing animation message
        setMessages(prevMessages => prevMessages.slice(0, -1));
      }

      setAreInputButtonsDisabled(false);
    };

    reader.readAsArrayBuffer(mp3Blob);
  };

  return {
    messages,
    areInputButtonsDisabled,
    latestAudioMessageId,
    handleTextSubmit,
    handleMP3Submit,
    isDeletingChat,
    handleDeleteClick,
    handleDeleteConfirm,
    handleDeleteCancel,
    isDeleteModalOpen,
    playingStateMap,
    setPlayingStateMap,
    stopAllAudios,
    audioMap,
    setAudioMap,
  };
};

export default useChat;
