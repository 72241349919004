import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ContactFormTakeover from '../components/LandingPage/ContactFormTakeover';
import CTA from '../components/LandingPage/CTA';
import FAQ from '../components/LandingPage/FAQ';
import FeatureCards from '../components/LandingPage/FeatureCards';
import FollowUpCTA from '../components/LandingPage/FollowUpCTA';
import Footer from '../components/LandingPage/Footer';
import LoginButton from '../components/LandingPage/LoginButton';
import WhyUs from '../components/LandingPage/WhyUs';
import LogoAndTitle from '../components/LogoAndTitle';
import { useAuthContext } from '../contexts/AuthContext';
import useIsTabletOrLarger from '../hooks/useIsTabletOrLarger';
import { PATHS, EVENTS } from '../utils/constants';
import Reviews from '../components/LandingPage/Reviews';
// import VideoPlayer from '../components/LandingPage/VideoPlayer';

const CONTACT_FORM_TAKEOVER = 'contact-form-takeover';

const LandingPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const isTabletOrLarger = useIsTabletOrLarger();
  const [isContactFormTakeoverOpen, setIsContactFormTakeoverOpen] =
    useState(false);

  useEffect(() => {
    if (currentUser) navigate(PATHS.CHAT);
  }, [currentUser, navigate]);

  /**
   * Handle contact form takeover blur
   */
  useEffect(() => {
    if (!isTabletOrLarger) return;

    const handleBlur = (e: MouseEvent) => {
      if ((e.target as HTMLElement).id === CONTACT_FORM_TAKEOVER) {
        setIsContactFormTakeoverOpen(false);
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [isTabletOrLarger]);

  return (
    <div className='bg-gradient-to-b from-blue-600 to-blue-500'>
      <LogoAndTitle />
      <LoginButton />
      <CTA />
      <WhyUs />
      <Reviews />
      <FAQ />
      <FeatureCards />
      {/* <VideoPlayer videoId='r3iRxbSRAl4' title='Demo' /> */}
      <FollowUpCTA />
      <Footer {...{ setIsContactFormTakeoverOpen }} />
      {isContactFormTakeoverOpen && (
        <ContactFormTakeover
          id={CONTACT_FORM_TAKEOVER}
          closeContactFormTakeover={() => setIsContactFormTakeoverOpen(false)}
        />
      )}
    </div>
  );
};

export default LandingPage;
