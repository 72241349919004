import apiClient from '..';
import { PatchChatArgs } from './types';

const deleteChat = async (userId: string, teacherId: string) => {
  const { data } = await apiClient.delete(`/api/chat/${userId}/${teacherId}`);

  return data;
};

const patchChat = async ({
  userId,
  teacherId,
  messages,
  teacherName,
  userPreferences,
  mp3Base64Str,
  userPreferredSpeed,
  isFemale,
  isAutoPlayOn,
  language,
  languageCode,
  voice,
  azureVoice,
  ttsProvider,
  summaries,
  textToTextModel,
}: PatchChatArgs) => {
  console.time('patchChat');

  const { data } = await apiClient.patch(`/api/chat/${userId}/${teacherId}`, {
    messages,
    teacherName,
    userPreferences,
    mp3Base64Str,
    userPreferredSpeed,
    isFemale,
    isAutoPlayOn,
    language,
    languageCode,
    voice,
    azureVoice,
    ttsProvider,
    summaries,
    textToTextModel,
  });

  console.timeEnd('patchChat');

  return data;
};

const fetchChat = async (
  userId: string,
  teacherId: string,
  userName: string,
  selectedLanguage: string,
) => {
  const { data } = await apiClient.post(`/api/chat/${userId}/${teacherId}`, {
    userName,
    selectedLanguage,
  });

  return data;
};

const chatAPI = {
  deleteChat,
  patchChat,
  fetchChat,
};

export default chatAPI;
