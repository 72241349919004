import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../utils/constants';

const LoginButton = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(PATHS.LOGIN)}
      className='bg-white flex hover:bg-gray-200 font-semibold text-black py-1 px-2 lg:p-2 rounded-md transition duration-200 absolute top-6 right-5 shadow-lg'
    >
      <LoginIcon className='mr-1' />
      <p>Login</p>
    </button>
  );
};

export default LoginButton;
