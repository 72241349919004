import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '../utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import { ReactComponent as TransparentLogo } from '../images/transparentLogo.svg';
import classNames from 'classnames';
// import { ReactComponent as TransparentLogoGlobeWithoutText } from '../images/transparentLogoGlobeWithoutText.svg';
// import { ReactComponent as TransparentLogoGlobeWithText } from '../images/transparentLogoGlobeWithText.svg';

const LogoAndTitle = () => {
  return (
    <>
      <TwoBubbles />
      {/* <GlobeWithoutText /> */}
      {/* <GlobeWithText /> */}
    </>
  );
};

const TwoBubbles = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const { pathname } = useLocation();

  const isLandingPage = pathname === PATHS.LANDING;

  const handleClick = () => {
    if (currentUser) return; //note why is this here
    if (isLandingPage) return;

    navigate(PATHS.LANDING);
  };

  return (
    <button
      onClick={handleClick}
      className={classNames(
        'flex items-center absolute top-1 left-1 lg:top-2 lg:left-2',
        isLandingPage && 'hover:cursor-default',
      )}
    >
      <TransparentLogo />
      <h1 className='text-2xl font-semibold text-white'>Lingua Chat</h1>
    </button>
  );
};

// const GlobeWithoutText = () => {
//   const navigate = useNavigate();
//   const { pathname } = useLocation();
//   const { currentUser } = useAuthContext();

//   const isLandingPage = pathname === PATHS.LANDING;

//   const handleClick = () => {
//     if (currentUser) return; //note why is this here
//     if (isLandingPage) return;

//     navigate(PATHS.LANDING);
//   };

//   return (
//     <button
//       onClick={handleClick}
//       className={classNames(
//         'flex items-center absolute top-0 left-0',
//         isLandingPage && 'hover:cursor-default',
//       )}
//     >
//       <TransparentLogoGlobeWithoutText />
//       <h1 className='text-2xl font-semibold text-white'>Lingua Chat</h1>
//     </button>
//   );
// };

// const GlobeWithText = () => {
//   const navigate = useNavigate();
//   const { pathname } = useLocation();
//   const { currentUser } = useAuthContext();

//   const isLandingPage = pathname === PATHS.LANDING;

//   const handleClick = () => {
//     if (currentUser) return; //note why is this here
//     if (isLandingPage) return;

//     navigate(PATHS.LANDING);
//   };

//   return (
//     <button
//       onClick={handleClick}
//       className={classNames(
//         'flex items-center absolute top-0 left-0 -translate-y-1/3 py-8',
//         isLandingPage && 'hover:cursor-default',
//       )}
//     >
//       <TransparentLogoGlobeWithText className='w-40 md:h-40 lg:w-48 lg:h-48' />
//     </button>
//   );
// };

export default LogoAndTitle;
